import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import {
  CalculoCondicao,
  CalculoResponse,
  InputDate,
  InputMoney,
  InputSelect,
} from '~/components/NovosInputs';
import { ClearButton, ConfirmButton } from '~/components/Buttons';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import {
  Parcela,
  ParcelaFinanceiro,
  SelectType,
} from '~/pages/DevolucaoESaidaNFDeFornecedor/protocols';

import clearForm from './clearForm.json';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Separator from '~/components/Separator';
import { ButtonItens } from './styles';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { CalcDataCondicao } from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';
import { getFinalizadora } from '~/pages/DevolucaoESaidaNFDeFornecedor/services';
import { deleteItens, insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import { FaSync } from 'react-icons/fa';

const MySwal = withReactContent(Swal);

export const FormParcelas: React.FC = () => {
  const [numParcela, setNumParcela] = useState(1);
  const [datasNaoInformadas, setDatasNaoInformadas] = useState<boolean>(false);
  const [finalizadoras, setFinalizadoras] = useState<SelectType[]>([]);

  const { codLoja } = useDevolucaoSaidaNFEForn();

  const {
    getValues,
    setCurrentTab,
    produtos,
    parcelasFinanceiro,
    setParcelasFinanceiro,
    parcelaSelecionada,
    subTotais,
    setParcelaSelecionada,
    isUpdate,
    formFinanceiro: {
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      getValues: getValuesFinanceiro,
      formState: formStateFinanceiro,
      watch,
      trigger,
      clearErrors,
    },
    masterDetail,
    setMasterDetail,
    uuidParcela,
    setUuidParcela,
  } = useDevolucaoSaidaNFEForn();

  useEffect(() => {
    (async () => {
      const options = await getFinalizadora(codLoja);
      setFinalizadoras(options);
    })();
  }, [codLoja]);

  useEffect(() => {
    if (parcelasFinanceiro.length !== 0) {
      const totalParcelas = parcelasFinanceiro.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = subTotais.totalNf - totalParcelas;
      setTimeout(() => {
        setValue(
          'val_financeiro',
          formatCurrencyAsText(resultado > 0 ? resultado : 0),
        );
      }, 20);
    } else {
      setValue('val_financeiro', formatCurrencyAsText(subTotais.totalNf));
    }
  }, [parcelasFinanceiro, subTotais.totalNf]);

  const handleClearForm = useCallback(() => {
    reset(clearForm);
    setDatasNaoInformadas(false);
    setUuidParcela(undefined);
  }, [reset]);

  const handleVencimento = useCallback(
    (condicaoData: CalculoResponse) => {
      if (condicaoData.error === 'Datas Devem ser informadas.') {
        setDatasNaoInformadas(true);
        return;
      }
      setValue(
        'dta_vencimento',
        format(new Date(condicaoData.vencimento), 'yyyy-MM-dd'),
      );
      setValue('num_condicao', condicaoData.numDias);
      setValue('condicao', condicaoData.condicao);
    },
    [setValue],
  );

  const handleAddParcela = handleSubmit(async () => {
    const uuid = uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
    const {
      condicao,
      num_condicao,
      finalizadora,
      dta_vencimento,
      val_financeiro,
    } = getValuesFinanceiro();

    const data: Parcela = {
      uuid,
      id: uuid,
      cod_parcela: uuidParcela?.cod_parcela,
      num_registro: parcelasFinanceiro.length + 1,
      cod_condicao: condicao.value,
      des_condicao: condicao.label,
      num_condicao,
      cod_finalizadora: finalizadora.value,
      des_finalizadora: `${finalizadora.value} - ${finalizadora.label}`,
      dta_vencimento,
      val_parcela: transformAsCurrency(val_financeiro),
    };
    const parcelasData: any[] = await insertOrUpdate(
      'parcelas',
      data,
      masterDetail,
      setMasterDetail,
    );

    const othersParcels = parcelasData.filter((p) => p.uuid !== uuid);
    const parcels = [...othersParcels, ...parcelasData];

    parcels.forEach((parcel) => {
      deleteItens('parcelas', parcel.uuid, masterDetail, setMasterDetail);
    });

    parcels.sort((a, b) => a.num_condicao - b.num_condicao);

    const seen = new Set<string>();
    const result: any[] = [];

    parcels.forEach((op) => {
      const key = [
        'cod_condicao',
        'cod_finalizadora',
        'cod_parcela',
        'des_condicao',
        'num_condicao',
      ]
        .map((k) => op[k])
        .join('|');

      if (!seen.has(key)) {
        seen.add(key);
        result.push(op);
      }
    });

    const parcelCorrigida = result.map((p) => {
      const [firstPart, ...restParts] = p.des_finalizadora.split(' - ');
      const lastPart = restParts.length ? restParts[restParts.length - 1] : '';
      const description = restParts.length
        ? `${firstPart} - ${lastPart}`
        : firstPart;

      return { ...p, des_finalizadora: description };
    });

    parcelCorrigida.forEach(async (p) => {
      await insertOrUpdate('parcelas', p, masterDetail, setMasterDetail);
    });
    setParcelasFinanceiro(parcelCorrigida);
    setTimeout(() => {
      handleClearForm();
    }, 50);
  });

  const removeParcelasRecalculo = () => {
    const parcelasFind = masterDetail.find(
      (item) => item.obj_name === 'parcelas',
    );
    parcelasFind?.itens.insert.forEach((parcela) => {
      deleteItens('parcelas', parcela.uuid, masterDetail, setMasterDetail);
    });
  };

  const handleRecalculaFinanceiro = async () => {
    const isInValid = await validation();
    if (isInValid) return;
    const { cod_pessoa } = getValues('cod_pessoa');
    const { tipo_operacao } = getValues('cod_perfil');
    let realizaBuscaParcelas = true;
    if (parcelasFinanceiro.length !== 0) {
      await MySwal.fire({
        title:
          'Deseja pesquisar novamente as condições de pagamento deste parceiro/perfil?',
        text: 'As parcelas atuais poderão sofrer alteração de prazos, formas de pagamento e valores.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          realizaBuscaParcelas = true;
        } else {
          realizaBuscaParcelas = false;
        }
      });
    }
    if (realizaBuscaParcelas) {
      try {
        const res = await api.get(
          `/nf-financeiro/parceiro/${cod_pessoa}/tipo-condicao/${tipo_operacao}`,
        );
        const { data } = res.data;
        if (data.length > 0) {
          const {
            cod_perfil: { cod_cc, cod_categoria },
            dta_emissao,
            dta_entrada,
          } = getValues();

          const { totalNf } = subTotais;
          const valParcela = data.length <= 0 ? 0 : totalNf / data.length;
          const options = data.map((item: any, index: number) => {
            const uuid =
              uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
            const dta_vencimento = CalcDataCondicao(
              cod_cc,
              dta_emissao,
              dta_entrada,
              item.num_condicao,
              0,
            );
            return {
              uuid,
              id: uuid,
              cod_parcela: uuidParcela?.cod_parcela,
              num_registro: index + 1,
              num_condicao: item.num_condicao,
              cod_condicao: item.cod_condicao,
              des_condicao: item.des_condicao,
              val_parcela: transformAsCurrency(valParcela),
              cod_finalizadora: item.cod_finalizadora,
              des_finalizadora: item.des_finalizadora,
              cod_cc,
              cod_categoria,
              dta_vencimento: `${format(
                new Date(dta_vencimento),
                'yyyy-MM-dd',
              )}`,

              flg_quitado: false,
            };
          });
          removeParcelasRecalculo();
          options.forEach(async (opt: any) => {
            await insertOrUpdate(
              'parcelas',
              opt,
              masterDetail,
              setMasterDetail,
            );
          });
          setParcelasFinanceiro(options);
        } else {
          const {
            cod_perfil: { cod_cc, cod_categoria, cod_perfil },
            dta_emissao,
            dta_entrada,
          } = getValues();
          const apiResponse = await api.get(`/busca-parcelas/${cod_perfil}`);
          const { data: dataReponse } = apiResponse.data;
          if (dataReponse.length > 0) {
            const { totalNf } = subTotais;
            const valParcela =
              dataReponse.length <= 0 ? 0 : totalNf / dataReponse.length;
            const options = dataReponse.map((item: any, index: number) => {
              const uuid =
                uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
              const dta_vencimento = CalcDataCondicao(
                cod_cc,
                dta_emissao,
                dta_entrada,
                item.num_condicao,
                0,
              );
              return {
                uuid,
                id: uuid,
                cod_parcela: uuidParcela?.cod_parcela,
                num_registro: index + 1,
                num_condicao: item.num_condicao,
                cod_condicao: item.cod_condicao,
                des_condicao: item.des_condicao,
                val_parcela: transformAsCurrency(valParcela),
                cod_finalizadora: item.cod_finalizadora,
                des_finalizadora: item.des_finalizadora,
                cod_cc,
                cod_categoria,
                dta_vencimento: `${format(
                  new Date(dta_vencimento),
                  'yyyy-MM-dd',
                )}`,
                flg_quitado: false,
              };
            });
            removeParcelasRecalculo();
            options.forEach(async (opt: any) => {
              await insertOrUpdate(
                'parcelas',
                opt,
                masterDetail,
                setMasterDetail,
              );
            });
            setParcelasFinanceiro(options);
          } else {
            Swal.fire({
              text: `Nenhuma condição de Pagamento localizada para esse parceiro/perfil`,
              icon: 'info',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } catch (error: any) {
        if (error.data && error.data.message) {
          toast.error(error.data.message);
          return;
        }
        toast.error(String(error));
      }
    }
  };

  const validation = useCallback(async () => {
    const cod_pessoa = getValues('cod_pessoa');
    const dataEmissao = getValues('dta_emissao');
    const dataEntrada = getValues('dta_entrada');

    if (cod_pessoa === undefined || cod_pessoa === '') {
      toast.warning('Fornecedor deve ser selecionado');
      return true;
    }
    if (dataEmissao === '' || dataEmissao === undefined) {
      toast.warning('Data de Emissão deve ser informada.');
      return true;
    }

    if (dataEntrada === '') {
      toast.warning('Data de Saída deve ser informada');
      return true;
    }
    if (produtos.length === 0) {
      toast.warning('Item(s) da NF deve(m) ser informado');
      return true;
    }
    return false;
  }, [getValues, produtos]);

  return (
    <>
      <Separator labelText="Financeiro" childrenWidth="150px">
        <ButtonItens
          type="button"
          onClick={() => {
            if (!isUpdate) {
              handleRecalculaFinanceiro();
            }
          }}
        >
          <FaSync color="#28a745" />
          <span>(Re) Calcular</span>
        </ButtonItens>
      </Separator>
      <Row
        className="d-flex align-items-center position-relative"
        style={{ zIndex: 3 }}
      >
        <Col sm={12} md={3}>
          <InputSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            register={register}
            disabled={isUpdate}
            isError={!!formStateFinanceiro.errors.finalizadora}
            control={control}
            changeSelected={(selected) => {
              clearErrors('finalizadora');
              setValue('finalizadora', selected);
            }}
            options={finalizadoras}
          />
        </Col>
        <CalculoCondicao
          name="condicaoNFSaida"
          register={register}
          getValues={getValuesFinanceiro}
          control={control}
          setValue={setValue}
          formState={formStateFinanceiro}
          watch={watch}
          fieldToWatch="finalizadora"
          disabled={isUpdate}
          dataEmissao={getValues('dta_emissao')}
          dataEntrada={getValues('dta_entrada')}
          changeCondicao={(result: any) => {
            clearErrors('condicao');
            handleVencimento(result);
          }}
        />
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            disabled={isUpdate}
            control={control}
            isError={!!formStateFinanceiro.errors.dta_vencimento}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            max={999999.01}
            min={0.0}
            disabled={isUpdate}
            name="val_financeiro"
            register={register}
            isError={!!formStateFinanceiro.errors.val_financeiro}
          />
        </Col>
        <Col md={12} sm={12} className="d-flex mt-2 justify-content-end">
          <ConfirmButton
            disabled={isUpdate}
            onClick={() => handleAddParcela()}
          />
          <ClearButton disabled={isUpdate} onClick={() => handleClearForm()} />
        </Col>
        <Col sm={12} style={{ marginTop: '15px' }}>
          {datasNaoInformadas === true && (
            <Alert variant="warning">
              Para o cálculo do vencimento é necessário{' '}
              <b
                onClick={() => {
                  setCurrentTab('nfe');
                }}
                style={{ cursor: 'pointer' }}
              >
                informar
              </b>{' '}
              as datas de emissão e saída da NF.
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};
